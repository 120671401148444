import Task, { TaskDefinition } from "@task";
import { CSSProperties, useEffect } from "react";
import React, { useContext, useState } from "react";
import { ChallengeDefinition, DuelDefinition } from "../../engine/models/challenge";
import styles from "./Challenge.module.scss";
import progressBarEmpty from './progress_bar_empty.png';
import progressBarFull from './progress_bar_full.png';
import { AreaNodeMasteries, GameGlobals } from "engine/game";
import { GameGlobalsContext } from "contexts/GameGlobalsContext";
import { ChallengeFeedback, useFeedbackState } from "./ChallengeFeedback";
import goldenArrowAnimated from './golden_arrow animated.png'
import { WriteSentence } from "@task/TaskUtils";
import { Score } from "components/sideMenus/Stats";
import fireStreak from './fire-streak.svg'
import heroFailed from './hero-failed.png'
import gameService from "services/gameService";
import reportProblemSvg from './report_a_problem.svg'
import iconCorrectSvg from './icon_correct.svg'
import ReportBug from "./ReportBug";
import { useStudent } from "providers/SignInProvider";
import profileService from "services/profileService";
import { DecideIfBonus } from "./DecideBonus";
import ChallengeReward from "./ChallengeReward";


/** Create a CSS clip-path property value representing progress.
 *
 * The clip-path is an inset rectangle covering the full height of its object,
 * expanding left-to-right from zero-width at `completion = 0` to full width at `completion = 1`.
 *
 * @param completion A value in the range 0 to 1 (inclusive).
 */
const progressClipPathValue = (completion: number) => `inset(0 ${100 - 100 * completion}% 0 0)`;

/** Generate a CSS clip-path style object representing progress.
 *
 * @param completion A value in the range 0 to 1 (inclusive).
 */
const progressClipPathStyle = (completion: number): CSSProperties => {
    const path = progressClipPathValue(completion);
    return { clipPath: path, WebkitClipPath: path };
};

export type ChallengeProps = ChallengeDefinition & {
    score: Score,
    setTotalScore: (newScore: number) => void
    getTask: () => TaskDefinition;
    onRetreat: () => void;
    onSuccess: (options?: { value: number, type: string }) => void;
    saveAnswer: (task: TaskDefinition, isCorrect: boolean, selectedAnswer?: string, studentId?: string, bonusPoints?: number) => void
    currentNodeMasteries?: AreaNodeMasteries,
    setCurrentMasteries?: (node: string, scoreUpdate: number) => void,
    timer?: number,
};

const avatarUrl = (avatar: string, gameGlobals: Partial<GameGlobals>): string => {
    return `./assets/${gameGlobals.adventure}-${gameGlobals.theme}/${avatar}.png`;
}

export const Avatar = (props: { avatar: string | null; }): JSX.Element => {
    const gameGlobals = useContext(GameGlobalsContext);

    const style: React.CSSProperties = {
        backgroundImage: props.avatar == null ? undefined : `url('${avatarUrl(props.avatar, gameGlobals)}')`,
    };

    return (
        <div className={styles.avatarContainer}>
            <div className={styles.avatar} style={style}></div>
        </div>
    );
};

export const ChallengeScene = (props: ChallengeProps & { children: JSX.Element | JSX.Element[]; }): JSX.Element => {
    return (
        <div className={styles.scene}>
            {/* <Avatar avatar={props.playerAvatar} /> */}
            {/* <Avatar avatar={null} /> */}
            <Avatar avatar={props.otherAvatar} />
            <br />
            <div className={styles.sceneContent}>{props.children}</div>
        </div>
    );
};

export const ChallengeIntroduction = (props: ChallengeProps & { onChallenge: () => void; }): JSX.Element => {
    return (
        <ChallengeScene {...props}>
            <WriteSentence sentenceFragment={props.introText} />
            <p>
                <button style={{ padding: '10px', marginBottom: '10px' }} onClick={props.onChallenge}>{props.challengeText}</button>
                <br />
                <button style={{ padding: '10px' }} onClick={props.onRetreat}>{props.retreatText}</button>
            </p>
        </ChallengeScene>
    );
};

export const ChallengeSuccess = (props: ChallengeProps): JSX.Element => {

    return (
        <ChallengeScene {...{ ...props, otherAvatar: 'victory-trumpet' }}>
            <WriteSentence sentenceFragment={props.successText} />
            <p>
                <img src={goldenArrowAnimated} className={styles.continueButton} alt="Golden arrow" onClick={() => props.onSuccess()} />
            </p>
        </ChallengeScene>
    );
};

export const ChallengeFailure = (props: ChallengeProps & DuelDefinition): JSX.Element => {
    return (
        <ChallengeScene {...props}>
            <p style={{ padding: '10px', marginBottom: '10px' }}>{props.failureText}</p>
            <img style={{ maxWidth: '200px' }} src={heroFailed} alt='hero failed' />
            <br />
            <button style={{ marginTop: '20px', padding: '10px' }} onClick={props.onRetreat}>Försök igen</button>
        </ChallengeScene>
    );
};

export type ChallengeState = 'intro' | 'challenge' | 'success' | 'failure' | 'reward';

export type ChallengeStateMachineProps = ChallengeProps & {
    questionNr?: number;
    state: ChallengeState;
    task?: TaskDefinition;
    bugReportSent: boolean;
    bugReportSending: boolean;
    setOpenBugReport: (open: boolean) => void;
    handleReward: () => void;
    nextTask: () => void;
    scoreTask: (task: TaskDefinition, score: number, correctAnswer: string, nodeRules: string, selectedAnswer?: string) => void;
};


export const ChallengeStateMachine = (props: ChallengeStateMachineProps): JSX.Element => {
    const [disableNextButton, setDisableNextButton] = useState<boolean>(false);
    const changeDifficulty = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setDisableNextButton(true);
        const difficulty = event.target.value
        let difficultyMin = -1;
        let difficultyMax = -1;
        if (difficulty === 'easy') {
            difficultyMin = 0;
            difficultyMax = 500;
        } else if (difficulty === 'medium') {
            difficultyMin = 400;
            difficultyMax = 700;
        } else if (difficulty === 'hard') {
            difficultyMin = 600;
            difficultyMax = 1000;
        } else if (difficulty === 'all') {
            difficultyMin = -1;
            difficultyMax = -1;
        }
        profileService.setUserDifficulty({ min: difficultyMin.toString(), max: difficultyMax.toString() }).finally(() => {
            setDisableNextButton(false);
            window.location.reload();
        });
    }

    const student = useStudent();
    const getDefaultValue = () => {
        const difficulty = student?.playerSettings?.difficulty?.min;
        if (difficulty === '0') return 'easy';
        if (difficulty === '400') return 'medium';
        if (difficulty === '600') return 'hard';
        if (difficulty === '-1') return 'all';
        return 'easy'
    }

    const invalidStateError = <>
        <div style={{ fontFamily: "Architects Daughter, cursive", marginTop: '20px' }}>Övningsområdet saknar frågor på vald nivå</div>
        <br />
        <br />
        <div >
            Vald nivå: <select onChange={changeDifficulty} defaultValue={getDefaultValue()}>
                <option value="easy">Vit</option>
                <option value="medium">Blå</option>
                <option value="hard">Svart</option>
                <option value="all">Alla nivåer</option>
            </select>
        </div>
        {/* <br /> */}
        <div style={{ margin: '25px 0' }}>Du kan också ändra din nivå där du väljer övningsområden</div>
        <br />
        <img id='challenge_close_button_invalid_state' src={goldenArrowAnimated} alt="Close button" style={{
            minWidth: '50px', marginTop: '25px', width: '10%', textAlign: 'center', display: 'block', marginLeft: 'auto',
            marginRight: 'auto'
        }} data-testid='scene_close_button' onClick={props.onRetreat} />
    </>;
    const states: Record<ChallengeState, () => JSX.Element> = {
        intro: () => {
            return <ChallengeIntroduction {...props} onChallenge={props.nextTask} />;
        },
        challenge: () => {
            if (!props.task) return invalidStateError;
            return (
                <div className={styles.scene}>
                    <div className={styles.sceneContent}>
                        <Task questionNr={props.questionNr} data={props.task} onCompletion={props.scoreTask} />
                        <br />
                        <div className={styles.buttonRow}>
                            <div className={styles.buttonBox} onClick={props.onRetreat}>
                                <img id='challenge_close_button' className={styles.buttonImage} src='./assets/close-with-background.png' alt="Close button" data-testid='scene_close_button' />
                                <div>Avbryt fråga</div>
                            </div>
                            <div className={styles.buttonBox} onClick={() => props.bugReportSending || props.bugReportSent ? {} : props.setOpenBugReport(true)}>
                                {!props.bugReportSent ? <><img id='report_problem' className={`${styles.buttonImage} ${props.bugReportSending ? styles.sending : ''}`} src={reportProblemSvg} alt='report a problem' />
                                    <div>Anmäl fråga</div>
                                </> : <><img id='bug_reported' className={styles.reportSentImg} src={iconCorrectSvg} alt='icon correct' />
                                    <div>Frågan är anmäld</div>
                                </>}
                            </div>
                        </div>

                    </div>
                </div>
            );
        },
        success: () => {
            return <ChallengeSuccess {...props} />;
        },
        reward: () => {
            return <ChallengeReward {...props} />;
        },
        failure: () => {
            if (props.type !== 'duel') return invalidStateError;
            return <ChallengeFailure {...props} />;
        },
    };
    return states[props.state]();
};

export const Challenge = (props: ChallengeProps): JSX.Element => {
    const [state, setState] = useState<ChallengeState>('intro');
    const [challengeScore, setChallengeScore] = useState<number>(0);
    const [task, setTask] = useState<TaskDefinition>();
    const [feedbackState, setFeedbackState] = useFeedbackState();
    const [bugReportSending, setBugReportSending] = useState<boolean>(false)
    const [bugReportSent, setBugReportSent] = useState<boolean>(false)
    const [openBugReport, setOpenBugReport] = useState<boolean>(false)
    const [currentQuestion, setCurrentQuestion] = useState<number>(0)
    const student = useStudent();
    const [extraLives, setExtraLives] = useState<number>(student?.extraLives ?? 0)

    const nextTask = (): void => {
        setState('challenge');
        setTask(props.getTask());
        setCurrentQuestion(cq => cq + 1)
    };

    useEffect(() => {
        setBugReportSending(false)
        setBugReportSent(false)
    }, [task]);

    const handleSendBugReport = async (message: string) => {
        if (!bugReportSending && task) {
            setBugReportSending(true)
            await gameService.reportBug(task.id, message)
                .then(() => setBugReportSent(true))
                .finally(() => setBugReportSending(false))
        }
    }

    const getQuestion = (task: TaskDefinition) => {
        switch (task.type) {
            case 'MultipleChoice':
                return <WriteSentence sentenceFragment={task.question} />
            case 'FillGaps':
                return ''
            // return <WriteSentence sentenceFragment={task.words.} />
            case 'FreeText':
                return '';
            default:
                return <div>Question</div>
        }
    }

    const getAnswer = (task: TaskDefinition, answer: string, preamble: string, bold: boolean, italics: boolean) => {
        switch (task.type) {
            case 'MultipleChoice':
                let ans = <WriteSentence sentenceFragment={`${answer}`} />
                if (bold) { ans = <b>{ans}</b> }
                if (italics) { ans = <i>{ans}</i> }
                return <><div>{preamble} {ans}</div></>
            case 'FillGaps':
            case 'FreeText':
                const findBracketedWords = /(\[.*?\])/;
                const correctAnswerSplit = answer.split(findBracketedWords)
                return <><div>{preamble} {correctAnswerSplit.map(word => {
                    if (findBracketedWords.test(word)) {
                        let ans = <WriteSentence sentenceFragment={word} />
                        if (bold) { ans = <b>{ans}</b> }
                        if (italics) { ans = <i>{ans}</i> }
                        return ans
                    } else return <WriteSentence sentenceFragment={word} />
                })}
                </div></>
            default:
                return <div>Answer</div>
        }
    }

    const isReward = () => {
        const oneIn = 1
        return Math.floor(Math.random() * oneIn) === 0
    }

    const giveFeedback = (task: TaskDefinition, taskScore: number, correctAnswer: string, nodeRules: string, bonusPoints: number | undefined, selectedAnswer?: string): void => {
        const test = nodeRules?.split(/(?<!\|)\|(?!\|)/) // Split only on single | (used to separate the rule from the example) and not split for double || (which is used to indicate row divides later)
        const question = getQuestion(task)
        const formattedCorrectAnswer = getAnswer(task, correctAnswer, 'Rätt svar: ', true, false)
        const formattedGivenAnswer = selectedAnswer ? getAnswer(task, selectedAnswer, 'Du svarade: ', false, true) : ''
        const taskComment = task.comment
        if (taskScore === 0) {
            setFeedbackState({
                type: 'incorrect',
                taskId: task.id,
                taskType: task.type,
                taskNode: task.meta?.subarea ?? '',
                taskDifficulty: task.meta?.difficulty?.toString() ?? '',
                comment: 'Det var tyvärr fel svar.',
                question: question,
                correctAnswer: formattedCorrectAnswer,
                givenAnswer: formattedGivenAnswer,
                taskComment: taskComment ? <WriteSentence sentenceFragment={taskComment} /> : '',
                rule: test && test?.length > 0 ? <WriteSentence sentenceFragment={test[0]} /> : '',
                example: test && test?.length > 1 ? <WriteSentence sentenceFragment={test[1]} /> : '',
                score: props.score.score + taskScore,
                bugReportSent: bugReportSent,
                bugReportSending: bugReportSending,
                extraLives,
                setOpenBugReport: setOpenBugReport,
                onClick: (event, useLife) => {
                    if (props.type === 'duel' && (extraLives === 0 || useLife !== true)) {
                        setState('failure');
                    }
                    else if (props.requiredScore > 0 && challengeScore + taskScore >= props.requiredScore) {
                        setState('success');
                    } else {
                        gameService.saveExtraLives(extraLives - 1, student?.id?.toString() ?? '', false)
                            .then(result => student?.updateStudentContext());
                        setExtraLives(el => el - 1)
                        nextTask();
                    }
                },
            });

        } else {
            setFeedbackState({
                type: 'correct',
                taskId: task.id,
                taskType: task.type,
                taskNode: task.meta?.subarea ?? '',
                taskDifficulty: task.meta?.difficulty?.toString() ?? '',
                comment: 'Strålande! Du borde bli lärare när du blir stor!',
                question: question,
                correctAnswer: formattedCorrectAnswer,
                givenAnswer: formattedGivenAnswer,
                taskComment: taskComment ? <WriteSentence sentenceFragment={taskComment} /> : '',
                rule: test && test?.length > 0 ? <WriteSentence sentenceFragment={test[0]} /> : '',
                example: test && test?.length > 1 ? <WriteSentence sentenceFragment={test[1]} /> : '',
                score: props.score.score + taskScore,
                streakScore: <div style={{ padding: '10px', }}><img style={{ maxWidth: '30px', maxHeight: '30px' }} src={fireStreak} alt='fire streak' /> {+props.score.scoreInARow + 1} rätt i rad!</div>,
                bugReportSent: bugReportSent,
                bugReportSending: bugReportSending,
                bonusPoints,
                extraLives,
                setOpenBugReport: setOpenBugReport,
                onClick: () => {
                    if (props.requiredScore > 0 && challengeScore + taskScore >= props.requiredScore) {
                        isReward() ? setState('reward') : setState('success');
                    } else {
                        nextTask();
                    }
                },
            });
        }
    };
    const scoreTask = (task: TaskDefinition, taskScore: number, correctAnswer: string, nodeRules: string, selectedAnswer?: string): void => {
        
        if (feedbackState.type === '') {
            const bonusPoints = DecideIfBonus()
            giveFeedback(task, taskScore, correctAnswer, nodeRules, bonusPoints, selectedAnswer);
            setChallengeScore(+challengeScore + +taskScore);
            props.saveAnswer(task, taskScore !== 0, selectedAnswer, student?.id?.toString() ?? undefined, bonusPoints)
            props.setTotalScore(+taskScore);
            {props.setCurrentMasteries && props.setCurrentMasteries(task.meta?.subarea ?? '', taskScore)}
        } else {
            return
        }
        // Infinity is not serializable to JSON. Non-positive required scores
        // have no other meaning, so they might as well be treated like Infinity.
        // if (props.requiredScore > 0 && score + taskScore >= props.requiredScore) {
        //     setState('success');
        // } else {
        //     nextTask();
        // }
    };

    const handleReward = () => {
        setState('success');
    }

    // TODO: implement dueling
    return <>
        <div style={{ position: 'fixed', inset: 0, backgroundColor: '#000a' }}></div>
        <div className={styles.mouseCatcher} />
        <div className={styles.challenge}>
            {props.requiredScore > 0 && (
                <div className={styles.progress}>
                    <img src={progressBarEmpty} alt='Progressbar empty background' />
                    <img src={progressBarFull} style={progressClipPathStyle(challengeScore / props.requiredScore)} alt={`Progressbar ${challengeScore}/${props.requiredScore} filled`} />
                </div>
            )}
            {props.questionAmount && props.questionAmount > 0 && (
                <div className={styles.progress}>
                    <img src={progressBarEmpty} alt='Progressbar empty background' />
                    <img src={progressBarFull} style={progressClipPathStyle(props.questionAmount)} alt={`Progressbar ${props.questionAmount} filled`} />
                </div>
            )}
            {/* <div><button onClick={props.onRetreat}>Test close</button></div> */}
            {extraLives > 0 && <div style={{ textAlign: 'center' }}>Du har {extraLives} extraliv!</div>}

            <div>{props.timer && props.timer}</div>

            <ChallengeStateMachine {...props} questionNr={currentQuestion} state={state} task={task} nextTask={nextTask} scoreTask={scoreTask} bugReportSent={bugReportSent} bugReportSending={bugReportSending} setOpenBugReport={setOpenBugReport} handleReward={handleReward} />

            <ChallengeFeedback {...feedbackState} />

            <ReportBug isActive={openBugReport} setIsActive={setOpenBugReport} sending={bugReportSending} handleSendBugReport={handleSendBugReport} taskId={task?.id ?? '-1'} />
        </div>
    </>;
};

export default Challenge;
