
import React, { useCallback, useEffect, useState } from "react"
import { AreaNode } from "./area_nodes"
import styles from './../Settings.module.scss'
import { SaveStateHandler } from "engine/saveStateHandler"
import iconCorrect from './../small-check-mark-icon.svg';
import goldenArrow from './../golden-arrow.png'
import { Skin } from "../SkinChoiceSettings/SkinSettings"
import profileService from "services/profileService";
import { useStudent } from "providers/SignInProvider";
import gameService from "services/gameService";
import gemRedPng from './gemRed.png'
import gemGreenPng from './gemGreen.png'
import gemBluePng from './gemBlue.png'
import goldBarPng from './goldbar.png'
import silverBarPng from './silverbar.png'
import bronzeBarPng from './bronzebar.png'
import woodPng from './wood.png'
import stonePng from './stone.png'
import { AreaNodeMasteries } from "engine/game";

export type ChoiceNode = Omit<AreaNode, 'subareas'> & { isChosen: boolean, choiceSubNodes: ChoiceNode[], mastery?: number }

export type NodeSettingsProps = {
    nodes: ChoiceNode[],
    masteries: AreaNodeMasteries,
    setChosenNodes: (chosenNodes: ChoiceNode[]) => void,
    setShowGemRules: (value: boolean) => void,
    style?: React.CSSProperties
}

export type SettingsProps = {
    skins: Skin[]
    nodes: AreaNode[]
    masteries: AreaNodeMasteries
    onClose: () => void
    saveChanges: (skin: Skin, nodes: AreaNode[], difficultyChanged?: boolean) => void
    saveStateHandler: SaveStateHandler,
}

function getNodeMasteries(relation: { parent_id: number, parent_headline: string, child_id: number, child_headline: string }, masteries: { [key: string]: number }, relations: { parent_id: number, parent_headline: string, child_id: number, child_headline: string }[]): number {
    const mastery = masteries[relation.child_headline] ?? 0;
    const subnodeMasteries = relations.filter(r => r.parent_id === relation.child_id).map(r => getNodeMasteries(r, masteries, relations));
    { return mastery + subnodeMasteries.reduce((a, b) => a + b, 0); }
}

export const gemBlueAward = 250;
export const gemRedAward = 200;
export const gemGreenAward = 150;
export const golbarAward = 100;
export const silverAward = 50;
export const bronzeAward = 25;
export const woodAward = 10;
export const stoneAward = 1;

export const getGemImage = (totalMastery: number, setShowGemRules: (value: boolean) => void) => {
    if (totalMastery >= gemBlueAward) return <img className={styles.gemIcon} style={{ cursor: "pointer" }} onClick={() => setShowGemRules(true)} src={gemBluePng} alt='diamant' title="diamant" />
    else if (totalMastery >= gemRedAward) return <img className={styles.gemIcon} style={{ cursor: "pointer" }} onClick={() => setShowGemRules(true)} src={gemRedPng} alt='rubin' title="rubin" />
    else if (totalMastery >= gemGreenAward) return <img className={styles.gemIcon} style={{ cursor: "pointer" }} onClick={() => setShowGemRules(true)} src={gemGreenPng} alt='smaragd' title="smaragd" />
    else if (totalMastery >= golbarAward) return <img className={styles.gemIcon} style={{ cursor: "pointer" }} onClick={() => setShowGemRules(true)} src={goldBarPng} alt='guld' title="guld" />
    else if (totalMastery >= silverAward) return <img className={styles.gemIcon} style={{ cursor: "pointer" }} onClick={() => setShowGemRules(true)} src={silverBarPng} alt='silver' title="silver" />
    else if (totalMastery >= bronzeAward) return <img className={styles.gemIcon} style={{ cursor: "pointer" }} onClick={() => setShowGemRules(true)} src={bronzeBarPng} alt='brons' title="brons" />
    else if (totalMastery >= woodAward) return <img className={styles.gemIcon} style={{ cursor: "pointer" }} onClick={() => setShowGemRules(true)} src={woodPng} alt='wood' title="trä" />
    else if (totalMastery >= stoneAward) return <img className={styles.gemIcon} style={{ cursor: "pointer" }} onClick={() => setShowGemRules(true)} src={stonePng} alt='stone' title="sten" />
    else return undefined;
}

function getGem(node: ChoiceNode, masteries: { [key: string]: number }, relations: { parent_id: number, parent_headline: string, child_id: number, child_headline: string }[], setShowGemRules: (value: boolean) => void): React.ReactNode {
    const mastery = masteries[node.headline] ?? 0;
    const subnodeMasteries = relations.filter(r => +r.parent_id === +node.id).map(r => getNodeMasteries(r, masteries, relations));

    const lowestAward = Math.min(gemBlueAward, gemRedAward, gemGreenAward, golbarAward, silverAward, bronzeAward, woodAward, stoneAward);
    let totalMastery = 0;
    // We only get the top two layers of nodes to show in settings. So if it has subnodes it must be a top node.
    // otherwise it is a subnode or it is a top node with no subnodes.
    // rellations gets the whole depth of nodes
    if (node.choiceSubNodes && node.choiceSubNodes.length > 0) {
        // show the lowest mastery of the subnodes that has an award or the current mastery if it is higher. 
        const filteredMasteries = subnodeMasteries.filter(m => m >= lowestAward);
        const min = filteredMasteries.length > 0 ? Math.min(...filteredMasteries) : 0;
        totalMastery = Math.max(mastery, min);
    } else {
        // if this is a top node with no subnodes it will just be its mastery.
        // if it is a subnode it will be the sum of its mastery and the sum of its subnodes.
        totalMastery = mastery + subnodeMasteries.reduce((a, b) => a + b, 0);
    }
    return getGemImage(totalMastery, setShowGemRules) ?? <></>;
}

const DisplayNode = (node: ChoiceNode, handleChosenNodes: (id: string) => void, index: number, bold: boolean, setShowGemRules: (value: boolean) => void, masteries?: { [key: string]: number }, relations?: { parent_id: number, parent_headline: string, child_id: number, child_headline: string }[], parentIsSelected?: boolean) => {
    const [masteryIcon, setMasteryIcon] = useState<React.ReactNode | null>(null);
    useEffect(() => {
        if (masteries && relations) {
            setMasteryIcon(getGem(node, masteries ?? {}, relations ?? [], setShowGemRules));
        }
    }, [masteries, relations, node]);

    return <span key={node.id}>
        <div className={styles.nodeRow}>
            {masteryIcon}
            <input className={styles.nodeCheckbox} id={'node_' + node.id} type="checkbox" onChange={() => handleChosenNodes(node.id)} checked={node.isChosen || parentIsSelected} />
            <label className={bold ? styles.nodeLabelBold : styles.nodeLabel} htmlFor={'node_' + node.id}>{node.headline}</label>
        </div>
        {node.choiceSubNodes && node.choiceSubNodes.length > 0 && <ul className={styles.nodeList}>{
            node.choiceSubNodes.map(cn => cn.headline.trim() !== '' && <li key={cn.id}>{DisplayNode(cn, handleChosenNodes, index, false, setShowGemRules, masteries, relations, node.isChosen)}</li>)
        }</ul>}
    </span>
}

export const getChosenNodes = (nodes: ChoiceNode[]): ChoiceNode[] => {
    return nodes.flatMap(cn => {
        const tempNodes = getChosenNodes(cn.choiceSubNodes)
        if (cn.isChosen) tempNodes.push(cn)
        return tempNodes;
    })
}

const displayChosenNodes = (chosenNodes: ChoiceNode[]) => {
    const displayNodes = getChosenNodes(chosenNodes)
    return displayNodes.length > 0 ? displayNodes.map(dn => dn.headline).join(',') : undefined
}

const setNodeAsChosen = (nodes: ChoiceNode[], id: string) => {
    nodes.map((tn) => {
        if (id === tn.id) {
            tn.isChosen = !tn.isChosen
        } else {
            tn.isChosen = false;
            // tn.choiceSubNodes = setNodesAsChosen(tn.choiceSubNodes, id);
        }
        tn.choiceSubNodes = setNodeAsChosen(tn.choiceSubNodes, id);
        return tn
    })
    return nodes
}

const getNodeListLength = (nodes: ChoiceNode[]): number => {
    if (!nodes || nodes.length === 0) return 0
    const sum = nodes.map(n => 1 + getNodeListLength(n.choiceSubNodes)).reduce((partialSum, value) => partialSum + value, 0)
    return sum
}



const NodeSettings = (props: NodeSettingsProps) => {
    const { nodes: chosenNodes, masteries, setChosenNodes, setShowGemRules, style } = props
    const student = useStudent();
    const [relations, setRelations] = useState<{ parent_id: number, parent_headline: string, child_id: number, child_headline: string }[]>();

    const handleChosenNodes = (id: string) => {
        let tempNodes = chosenNodes.slice();
        tempNodes = setNodeAsChosen(tempNodes, id)
        setChosenNodes(tempNodes)
    }

    useEffect(() => {
        let running = true;
        if (!relations) {
            gameService.getNodeRelations().then((relations) => {
                if (running && relations) {
                    setRelations(relations)
                }
            });
        }
        return () => { running = false }
    }, [relations]);


    return <div className={styles.settingsCard} style={style} data-testid='node_settings'>
        {chosenNodes.length > 0 ? <>
            <div className={styles.settingsList} data-testid='node_settings_choices_menu'>
                {chosenNodes.map((n, index) => n.headline.trim() !== '' && <div key={index}>
                    {DisplayNode(n, handleChosenNodes, index, true, setShowGemRules, masteries, relations)}
                </div>)}
            </div>
        </> : <div data-testid='node_settings_not_found'>Inga områden hittade</div>}
    </div>
}


export const convertNodeToChoiceNode = (node: AreaNode, ...chosenIds: string[]): ChoiceNode => {
    return { ...node, isChosen: chosenIds.some(ci => ci === node.id), choiceSubNodes: node.subareas ? node.subareas.map(n => convertNodeToChoiceNode(n, ...chosenIds)) : [] }
}

type Settings = {
    className: string
    saveChanges: (skin: Skin, nodes: AreaNode[], difficultyChange: boolean) => void
    chosenSkin?: Skin
    chosenNodes: ChoiceNode[]
    masteries: AreaNodeMasteries
    setChosenNodes: (chosenNodes: ChoiceNode[]) => void
    changeDifficulty: (event: React.ChangeEvent<HTMLSelectElement>) => void,
    difficultyChanged: boolean,
    disableSaveButton: boolean,
    setShowGemRules: (value: boolean) => void
}

const VerticleMobile = (props: Settings
    & {
}) => {
    const {
        className,
        saveChanges,
        chosenSkin,
        chosenNodes,
        setChosenNodes,
        masteries,
        changeDifficulty,
        difficultyChanged,
        disableSaveButton,
        setShowGemRules,
    } = props

    const student = useStudent();
    const getDefaultValue = () => {
        const difficulty = student?.playerSettings?.difficulty?.min;
        if (difficulty === '0') return 'easy';
        if (difficulty === '400') return 'medium';
        if (difficulty === '600') return 'hard';
        if (difficulty === '-1') return 'all';
        return 'easy'
    }

    return (
        <div className={className} data-testid='settings'>
            <div className={styles.sticky}>
                <span>
                    <div style={{ marginTop: '5%' }}>
                        <b data-testid='node_settings_header'>Övningsområde</b>
                    </div>
                    <span data-testid='node_settings_chosen'>
                        Valt område: {displayChosenNodes(chosenNodes) ?? 'Alla områden'}
                    </span>
                    <br />
                    <span>
                        Vald nivå: <select onChange={changeDifficulty} defaultValue={getDefaultValue()}>
                            <option value="easy">Vit</option>
                            <option value="medium">Blå</option>
                            <option value="hard">Svart</option>
                            <option value="all">Alla nivåer</option>
                        </select>
                    </span>
                </span>
                <button className={styles.arrowButton} disabled={disableSaveButton}
                    onClick={() => saveChanges(chosenSkin ?? { name: 'det stora äventyret', adventureId: 'bare', theme: 'default', image: '', description: 'detta är ett äventyr', isHorizontal: true }, getChosenNodes(chosenNodes), difficultyChanged)}>
                    <img src={goldenArrow} alt="arrow" data-testid='settings_save_button'
                    />
                </button>
            </div>
            <div className={styles.body}>
                {chosenNodes.length > 0 ? <>
                    <div style={{ gridArea: 'top' }}>
                        <input id="allNodes" type="checkbox" onChange={() => (setNodeAsChosen(chosenNodes, '-1'))} checked={displayChosenNodes(chosenNodes) === undefined} />
                        <label htmlFor="allNodes">Alla områden</label>
                    </div>
                    <NodeSettings nodes={chosenNodes} masteries={masteries} setChosenNodes={setChosenNodes} setShowGemRules={setShowGemRules}></NodeSettings>
                </> : <div data-testid='node_settings_not_found'>Inga områden hittade</div>}
            </div>
        </div>
    )
}

const HorizontalMobile = (props: Settings & {
    DivideNodesInHalves: (ChosenNodes: ChoiceNode[]) => ChoiceNode[][],
    setChosenNodesFirstHalf: (chosenNodes: ChoiceNode[]) => void,
    setChosenNodesSecondHalf: (chosenNodes: ChoiceNode[]) => void,
}) => {
    const {
        className,
        saveChanges,
        chosenSkin,
        chosenNodes,
        setChosenNodes,
        masteries,
        DivideNodesInHalves,
        setChosenNodesFirstHalf,
        setChosenNodesSecondHalf,
        changeDifficulty,
        difficultyChanged,
        disableSaveButton,
        setShowGemRules,
    } = props

    const student = useStudent();
    const getDefaultValue = () => {
        const difficulty = student?.playerSettings?.difficulty?.min;
        if (difficulty === '0') return 'easy';
        if (difficulty === '400') return 'medium';
        if (difficulty === '600') return 'hard';
        if (difficulty === '-1') return 'all';
        return 'easy'
    }

    return (
        <div className={className} data-testid='settings'>
            <div className={styles.sticky}>
                <span>
                    <b data-testid='node_settings_header' style={{ marginRight: '10%' }}>Övningsområde</b> <br />
                    <span style={{}} data-testid='node_settings_chosen'>
                        Valt område: {displayChosenNodes(chosenNodes) ?? 'Alla områden'}
                    </span>
                    <span>
                        Vald nivå: <select onChange={changeDifficulty} defaultValue={getDefaultValue()}>
                            <option value="easy">Vit</option>
                            <option value="medium">Blå</option>
                            <option value="hard">Svart</option>
                            <option value="all">Alla nivåer</option>
                        </select>
                    </span>
                </span>
                <button className={styles.arrowButton} disabled={disableSaveButton}
                    onClick={() => saveChanges(chosenSkin ?? { name: 'det stora äventyret', adventureId: 'bare', theme: 'default', image: '', description: 'detta är ett äventyr', isHorizontal: true }, getChosenNodes(chosenNodes), difficultyChanged)}>
                    <img src={goldenArrow} alt="arrow" data-testid='settings_save_button'
                    />
                </button>
            </div>
            <div className={styles.body}>
                {chosenNodes.length > 0 ? <>
                    <div className={styles.horizontalGrid}>
                        <div style={{ gridArea: 'top' }}>
                            <input id="allNodes" type="checkbox" onChange={() => (setNodeAsChosen(chosenNodes, '-1'))} checked={displayChosenNodes(chosenNodes) === undefined} />
                            <label htmlFor="allNodes">Alla områden</label>
                        </div>
                        <NodeSettings style={{ gridArea: 'left' }} nodes={DivideNodesInHalves(chosenNodes)[0]} masteries={masteries} setChosenNodes={setChosenNodesFirstHalf} setShowGemRules={setShowGemRules}></NodeSettings>
                        <NodeSettings style={{ gridArea: 'right' }} nodes={DivideNodesInHalves(chosenNodes)[1]} masteries={masteries} setChosenNodes={setChosenNodesSecondHalf} setShowGemRules={setShowGemRules}></NodeSettings>
                    </div>
                </> : <div data-testid='node_settings_not_found'>Inga områden hittade</div>}
            </div>
        </div>
    )
}

const Desktop = (props: Settings & {
    DivideNodesInHalves: (ChosenNodes: ChoiceNode[]) => ChoiceNode[][],
    setChosenNodesFirstHalf: (chosenNodes: ChoiceNode[]) => void,
    setChosenNodesSecondHalf: (chosenNodes: ChoiceNode[]) => void,
}) => {
    const {
        className,
        saveChanges,
        chosenSkin,
        chosenNodes,
        setChosenNodes,
        masteries,
        DivideNodesInHalves,
        setChosenNodesFirstHalf,
        setChosenNodesSecondHalf,
        changeDifficulty,
        difficultyChanged,
        disableSaveButton,
        setShowGemRules,
    } = props

    const student = useStudent();
    const getDefaultValue = () => {
        const difficulty = student?.playerSettings?.difficulty?.min;
        if (difficulty === '0') return 'easy';
        if (difficulty === '400') return 'medium';
        if (difficulty === '600') return 'hard';
        if (difficulty === '-1') return 'all';
        return 'easy'
    }

    return (
        <div className={className} data-testid='settings'>
            <div className={styles.sticky}>
                <span>
                    <b data-testid='node_settings_header' style={{ marginRight: '10%' }}>Övningsområde</b> <br />
                    <span style={{}} data-testid='node_settings_chosen'>
                        Valt område: {displayChosenNodes(chosenNodes) ?? 'Alla områden'}
                    </span>
                    <span style={{ marginLeft: "30px" }}>
                        Vald nivå: <select onChange={changeDifficulty} defaultValue={getDefaultValue()}>
                            <option value="easy">Vit</option>
                            <option value="medium">Blå</option>
                            <option value="hard">Svart</option>
                            <option value="all">Alla nivåer</option>
                        </select>
                    </span>
                </span>
                <button className={styles.arrowButton} disabled={disableSaveButton}
                    onClick={() => saveChanges(chosenSkin ?? { name: 'det stora äventyret', adventureId: 'bare', theme: 'default', image: '', description: 'detta är ett äventyr', isHorizontal: true }, getChosenNodes(chosenNodes), difficultyChanged)}>
                    <img src={goldenArrow} alt="arrow" data-testid='settings_save_button'
                    />
                </button>
            </div>
            <div className={styles.body}>
                {chosenNodes.length > 0 ? <>
                    <div className={styles.horizontalGrid}>
                        <div style={{ gridArea: 'top' }}>
                            <input id="allNodes" type="checkbox" onChange={() => (setNodeAsChosen(chosenNodes, '-1'))} checked={displayChosenNodes(chosenNodes) === undefined} />
                            <label htmlFor="allNodes">Alla områden</label>
                        </div>
                        <NodeSettings style={{ gridArea: 'left' }} nodes={DivideNodesInHalves(chosenNodes)[0]} masteries={masteries} setChosenNodes={setChosenNodesFirstHalf} setShowGemRules={setShowGemRules}></NodeSettings>
                        <NodeSettings style={{ gridArea: 'right' }} nodes={DivideNodesInHalves(chosenNodes)[1]} masteries={masteries} setChosenNodes={setChosenNodesSecondHalf} setShowGemRules={setShowGemRules}></NodeSettings>
                    </div>
                </> : <div data-testid='node_settings_not_found'>Inga områden hittade</div>}
            </div>
        </div>
    )
}

const GemRulesModal = (props: { setShowGemRules: (value: boolean) => void }) => {
    return (
        <div className={styles.gemRulesModal} id="gemRulesModal">
            <div className={styles.gemModalTitle}>
                Samla juveler och andra värdefulla föremål genom att svara rätt på frågor i olika övningsområden:
            </div>
            <div className={styles.gemRulesModalContent}>
                <div className={styles.gemRow}><img className={styles.gemIcon} src={stonePng} alt="sten" title="sten" /> = 1 rätt svar</div>
                <div className={styles.gemRow}><img className={styles.gemIcon} src={woodPng} alt="trä" title="trä" /> = 10 rätta svar</div>
            </div>
            <div className={`${styles.gemRulesModalContent} ${styles.gemRulesCenter}`}>
                <div className={styles.gemRow}><img className={styles.gemIcon} src={bronzeBarPng} alt="brons" title="brons" /> = 25 rätta svar</div>
                <div className={styles.gemRow}><img className={styles.gemIcon} src={silverBarPng} alt="silver" title="silver" /> = 50 rätta svar</div>
                <div className={styles.gemRow}><img className={styles.gemIcon} src={goldBarPng} alt="guld" title="guld" /> = 100 rätta svar</div>
            </div>
            <div className={`${styles.gemRulesModalContent} ${styles.gemRulesRight}`}>
                <div className={styles.gemRow}><img className={styles.gemIcon} src={gemGreenPng} alt="smaragd" title="smaragd" /> = 150 rätta svar</div>
                <div className={styles.gemRow}><img className={styles.gemIcon} src={gemRedPng} alt="rubin" title="rubin" /> = 200 rätta svar</div>
                <div className={styles.gemRow}><img className={styles.gemIcon} src={gemBluePng} alt="diamant" title="diamant" /> = 250 rätta svar</div>
            </div>
            <img id='report_bug_close_button' className={styles.closeButton} src='./assets/close-with-background.png' alt="Close button" data-testid='report_bug_close_button' onClick={() => props.setShowGemRules(false)} />
        </div>
    )
}


export const AreaNodesSettings = (props: SettingsProps): JSX.Element => {
    const {
        skins,
        nodes,
        masteries,
        onClose,
        saveChanges,
        saveStateHandler,
    } = props

    const [chosenNodes, setChosenNodes] = useState<(ChoiceNode)[]>([])
    const [chosenSkin, setChosenSkin] = useState<(Skin | undefined)>(undefined)
    const student = useStudent();
    const [difficultyChanged, setDifficultyChanged] = useState<boolean>(false);
    const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);
    const [showGemRules, setShowGemRules] = useState<boolean>(false);

    const changeDifficulty = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setDisableSaveButton(true);
        const difficulty = event.target.value
        let difficultyMin = -1;
        let difficultyMax = -1;
        if (difficulty === 'easy') {
            difficultyMin = 0;
            difficultyMax = 500;
            setDifficultyChanged(true);
        } else if (difficulty === 'medium') {
            difficultyMin = 400;
            difficultyMax = 700;
            setDifficultyChanged(true);
        } else if (difficulty === 'hard') {
            difficultyMin = 600;
            difficultyMax = 1000;
            setDifficultyChanged(true);
        } else if (difficulty === 'all') {
            difficultyMin = -1;
            difficultyMax = -1;
            setDifficultyChanged(true);
        }
        profileService.setUserDifficulty({ min: difficultyMin.toString(), max: difficultyMax.toString() })
            .then(() => {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({ event: 'changeDifficulty', difficultyProps: { difficulty } });
            }).finally(() => {
                setDisableSaveButton(false);
            });
    }

    const DivideNodesInHalves = useCallback((nodes: ChoiceNode[]) => {
        nodes.forEach(n => n.choiceSubNodes.forEach(sn => sn.choiceSubNodes = [])) // only show nodes and the direct subnodes
        const sortedNodes = nodes.sort((a, b) => getNodeListLength([b]) - getNodeListLength([a]))
        const nodeLengths = sortedNodes.map(n => getNodeListLength([n]))
        const desiredLength = Math.ceil(nodeLengths.reduce((sum, a) => sum + a, 0) / 2)

        // let dividingIndex: number = nodes.length
        let currentLength: number = 0
        const leftSideIndexes: number[] = []
        nodeLengths.forEach((length, index) => {
            if (index === 0) {
                leftSideIndexes.push(index)
                currentLength += length
            }
            else if (currentLength + length <= desiredLength) {
                leftSideIndexes.push(index)
                currentLength += length
            }
        });

        const first = sortedNodes.filter((n, index) => leftSideIndexes.some(li => li === index))
        const second = sortedNodes.filter((n, index) => leftSideIndexes.every(li => li !== index))
        return [first, second]
    }, [])

    const setChosenNodesFirstHalf = (updatedHalf: ChoiceNode[]) => {
        setChosenNodes(updatedHalf.concat(setNodeAsChosen(DivideNodesInHalves(chosenNodes)[1], '-1')))
    }

    const setChosenNodesSecondHalf = (updatedHalf: ChoiceNode[]) => {
        setChosenNodes(setNodeAsChosen(DivideNodesInHalves(chosenNodes)[0], '-1').concat(updatedHalf))
    }

    useEffect(() => {
        if (!chosenSkin) {
            saveStateHandler.loadScene().then(scene => {
                if (scene) {
                    const chosenSkin = skins.find(s => s.adventureId === scene.adventureId)
                    setChosenSkin(chosenSkin)
                }
            })
        }
        if (nodes.length !== 0 && chosenNodes.length === 0) {
            const params = new URLSearchParams(window.location.search);
            const paramValue = params.get("node");

            if (paramValue) {
                setChosenNodes(nodes.map((n) => {
                    return convertNodeToChoiceNode(n, paramValue)
                }))
            } else setChosenNodes(nodes.map((n) => convertNodeToChoiceNode(n)))
        }
    }, [nodes, chosenNodes, saveStateHandler, skins, chosenSkin])

    const settings: Omit<Settings, 'className'> = {
        saveChanges: saveChanges,
        chosenSkin: chosenSkin,
        chosenNodes: chosenNodes,
        masteries: masteries,
        setChosenNodes,
        changeDifficulty,
        difficultyChanged,
        disableSaveButton,
        setShowGemRules
    }

    return (<>
        <Desktop className={styles.settings + ' ' + styles.desktop}
            {...settings}
            DivideNodesInHalves={DivideNodesInHalves}
            setChosenNodesFirstHalf={setChosenNodesFirstHalf}
            setChosenNodesSecondHalf={setChosenNodesSecondHalf}
        />
        <HorizontalMobile className={styles.settings + ' ' + styles.horizontalMobile}
            {...settings}
            DivideNodesInHalves={DivideNodesInHalves}
            setChosenNodesFirstHalf={setChosenNodesFirstHalf}
            setChosenNodesSecondHalf={setChosenNodesSecondHalf}
        />
        <VerticleMobile className={styles.settings + ' ' + styles.verticleMobile}
            {...settings}
        />
        {showGemRules && <GemRulesModal {...{ setShowGemRules }} />}
        <div style={{ position: 'fixed', inset: 0, backgroundColor: '#000a' }}></div>
    </>)
}

export default AreaNodesSettings

