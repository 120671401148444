import React, { CSSProperties } from 'react'

export const WriteSentence = (props: { sentenceFragment: string, style?: CSSProperties  }) => {
    const { sentenceFragment, style } = props
    const handleSegment = (seg: string) => {
        if (seg.includes('^^')) {
            return seg.split(' ').map((word, index) => {
                return word.startsWith('^^') ? <span key={index} style={{ fontSize: '300%' }}>{word.replace('^^', '')} </span> : `${word} `
            });
        }
        else return seg
    }
    const spanStyle = style !== undefined ? style : {}
    return <span style={spanStyle}>{sentenceFragment.split('||').map((seg, splitIndex, arr) =>
        <span key={splitIndex}>
            {seg !== '' && <>{handleSegment(seg)}</>}
            {splitIndex !== arr.length - 1 && <br />}
        </span>
    )}</span>
}