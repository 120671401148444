import { OneHundred, Score } from 'components/sideMenus/Stats';
import { AreaNode } from 'components/settings/AreaNodeSettings/area_nodes';
import { GameGlobalsContext } from 'contexts/GameGlobalsContext';
import { AreaNodeMasteries, GameEngine, GameMode, GameState } from 'engine/game';
import React, { useContext, useEffect, useState } from 'react'
import AdventureTime from './adventureTime';
import OneHundredMode from './oneHundredMode';
import TimedMode from './timedMode';


const SelectedGameMode = (props: {
    mode: GameMode | undefined,
    engine: GameEngine,
    state: GameState,
    nodes: AreaNode[],
    masteries: AreaNodeMasteries,
    score: Score,
    setScore: (value: number) => void,
    showOverlay: boolean,
    setShowOverlay: (value: boolean) => void
}) => {
    const {
        mode,
        engine,
        state,
        nodes,
        masteries,
        score,
        setScore,
        showOverlay,
        setShowOverlay,
    } = props

    // const [currentMode, setCurrentMode] = useState<GameMode>()
    const [display, setDisplay] = useState<JSX.Element>()

    const gameGlobals = useContext(GameGlobalsContext);

    useEffect(() => {
        const HandleGameMode = () => {
            switch (mode?.type) {
                case 'Timed':
                    return <TimedMode {...{ state, engine, score, setScore, timer: gameGlobals.timer ?? 0, mode }} />
                case 'OneHundred':
                    return <OneHundredMode {...{ state, engine, score, setScore, mode }} />
                default:
                    return <AdventureTime {...{ state, engine, score, setScore, nodes, masteries, showOverlay, setShowOverlay }} />
            }
        }

        // setCurrentMode(mode)
        setDisplay(HandleGameMode())
    }, [mode, engine, state, nodes, score, setScore, setShowOverlay])




    return <>
        {display}
    </>
}

export default SelectedGameMode;