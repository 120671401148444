import React, { useEffect, useState } from "react"

import styles from './../Settings.module.scss'
import { AreaNode } from "../AreaNodeSettings/area_nodes"
import NodeSettings, { ChoiceNode, convertNodeToChoiceNode, getChosenNodes } from "../AreaNodeSettings/NodeSettings";
import { SaveStateHandler } from "engine/saveStateHandler";

export type Skin = {
    name: string,
    adventureId: string
    theme: string
    image: string
    description: string
    isHorizontal: boolean
}

export type SettingsProps = {
    skins: Skin[]
    nodes: AreaNode[]
    onClose: () => void
    saveChanges: (skin: Skin, nodes: AreaNode[]) => void
    saveStateHandler: SaveStateHandler,
}

type Settings = {
    className: string
    onClose: () => void
    skins: Skin[]
    handleOnClick: (skin: Skin) => void
}

const VerticleMobile = (props: Settings) => {
    const {
        className,
        onClose,
        skins,
        handleOnClick
    } = props

    return (
        <div className={className} data-testid='settings'>
            <div style={{ textAlign: 'right', }}>
                <img className={styles.closeButton} src='./assets/close-with-background.png' alt="X" data-testid='settings_close_button'
                    onClick={onClose} /></div>
            {skins.length > 0 && <div className={styles.settingsCard} data-testid='game_settings'>
                <div className={styles.settingsList} data-testid='game_settings_choices_menu' style={{ display: 'grid', gridAutoFlow: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center', }}>
                    {skins.map((s, index) =>
                        <div key={index} style={{ margin: '15px 15px 30px 15px' }}>
                            <img id={'skin_' + s.adventureId} style={{ maxHeight: '500px' }} src={s.image} alt={`${s.name}`} onClick={() => handleOnClick(s)} />
                            <br />
                            <b>{s.name}</b><br />
                            <label>{s.description}</label>
                            <div style={{ backgroundImage: `url(${s.isHorizontal ? './assets/flip-phone.png' : './assets/standing-mobile.png'})` }}> </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className={styles.orientation_img} style={{ backgroundImage: `url(${s.isHorizontal ? './assets/flip-phone.png' : './assets/standing-mobile.png'})` }}> </div>
                            </div>
                            {skins.length > index && <hr style={{ color: '#783f04' }} />}
                        </div>)}
                </div>
            </div>}
        </div>
    )
}

const HorizontalMobile = (props: Settings) => {
    const {
        className,
        onClose,
        skins,
        handleOnClick
    } = props

    return (
        <div className={className} data-testid='settings'>
            <div style={{ textAlign: 'right', }}>
                <img className={styles.closeButton} src='./assets/close-with-background.png' alt="X" data-testid='settings_close_button'
                    onClick={onClose} /></div>
            {skins.length > 0 && <div className={styles.settingsCard} data-testid='game_settings'>
                <div className={styles.settingsList} data-testid='game_settings_choices_menu' style={{ display: 'grid', gridAutoFlow: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                    {skins.map((s, index) =>
                        <div key={index} style={{ margin: '15px 15px' }} onClick={() => handleOnClick(s)}>
                            <img id={'skin_' + s.adventureId} style={{ width: `33vw` }} src={s.image} alt={`${s.name}`} />
                            <br />
                            <b>{s.name}</b><br />
                            <label>{s.description}</label>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className={styles.orientation_img} style={{ backgroundImage: `url(${s.isHorizontal ? './assets/flip-phone.png' : './assets/standing-mobile.png'})` }}> </div>
                            </div>
                        </div>)}
                    {/* <div></div> */}
                </div>
            </div>}
        </div>
    )
}

const Desktop = (props: Settings) => {
    const {
        className,
        onClose,
        skins,
        handleOnClick
    } = props
    return (
        <div className={className} data-testid='settings'>
            <div style={{ textAlign: 'right', }}>
                <img className={styles.closeButton} src='./assets/close-with-background.png' alt="Close" data-testid='settings_close_button'
                    onClick={onClose} /></div>
            {skins.length > 0 && <div className={styles.settingsCard} data-testid='game_settings'>
                <div className={styles.settingsList} data-testid='game_settings_choices_menu' style={{ display: 'grid', gridAutoFlow: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', }}>
                    {skins.map((s, index) =>
                        <div key={index} style={{ margin: '15px 15px' }}>
                            <img id={'skin_' + s.adventureId} style={{ width: '65%' }} src={s.image} alt={`${s.name}`} onClick={() => handleOnClick(s)} />
                            <br />
                            <br />
                            <b>{s.name}</b><br />
                            <label>{s.description}</label>
                        </div>)}
                </div>
            </div>}
        </div>
    )
}

export const SkinSettings = (props: SettingsProps): JSX.Element => {
    const {
        skins,
        nodes,
        onClose,
        saveChanges,
        saveStateHandler,
    } = props

    const [chosenNodes, setChosenNodes] = useState<(ChoiceNode)[]>([])
    const [chosenSkin, setChosenSkin] = useState<(Skin | undefined)>(undefined)

    const handleOnClick = (skin: Skin) => {
        saveChanges(skin, [])
    }

    useEffect(() => {
        if (!chosenSkin) {
            saveStateHandler.loadScene().then(scene => {
                if (scene) {
                    const chosenSkin = skins.find(s => s.adventureId === scene.adventureId)
                    setChosenSkin(chosenSkin)
                }
            })
        }
        if (nodes.length !== 0 && chosenNodes.length === 0) {
            const params = new URLSearchParams(window.location.search);
            const paramValue = params.get("node");

            if (paramValue) {
                setChosenNodes(nodes.map((n) => {
                    const test = convertNodeToChoiceNode(n)
                    test.isChosen = paramValue === test.id
                    return test;
                }))
            } else setChosenNodes(nodes.map((n) => convertNodeToChoiceNode(n)))
        }
    }, [nodes, chosenNodes, saveStateHandler, skins, chosenSkin])


    return (<>
        <Desktop {...props} className={styles.settings + ' ' + styles.desktop} handleOnClick={handleOnClick} />
        <HorizontalMobile {...props} className={styles.settings + ' ' + styles.horizontalMobile} handleOnClick={handleOnClick} />
        <VerticleMobile {...props} className={styles.settings + ' ' + styles.verticleMobile} handleOnClick={handleOnClick} />
        <div style={{position: 'fixed', inset: 0, backgroundColor: '#000a'}}></div>
    </>)
}

export default SkinSettings
